import { Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';
import {
  formatRefundDetail,
  formatRefundPaymentInfo,
  formatRefundAddressInfo,
  formatAccountInfo,
  formatReceiptForm,
  formatAccountEngInfo,
} from '../../../utils/ktp';
import FlexWrap from '@/components/common/FlexWrap';
import { getReceiptData, updateRefundDetail } from '@/api/ktp';
import Typography from '@/components/common/Typography';
import downloadIcon from '@assets/common/download.png';
import Icon from '@/components/common/Icon';
import ReceiptImage from '@/components/ktp/refund-status/ReceiptImage';
import { convertComponentToImage } from '@/utils/common';
import checkIconV2 from '@/assets/common/checkbox_v2.png';
import checkedIconV2 from '@/assets/common/checkbox_v2_fill.png';
import ModifyNeedCheckbox from '@/components/common/Remittance/ModifyNeedCheckbox';
import RemittanceStatusRadio from './RemittanceStatusRadio';
import PaymentWayRadio from './PaymentWayRadio';
import MailingStatusRadio from './MailingStatusRadio';

function DetailContent({ data, setData, refundIndex, onClose }) {
  const { detailRefundInfo, accountInfo, paymentStatus } = data;
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [isModifyNeeded, setIsModifyNeeded] = useState(
    data?.isModifyNeeded || false,
  );
  const [selectedAccountInfo, setSelectedAccountInfo] = useState(
    data?.selectedAccountInfo || [],
  );
  const checkPaymentInfo = accountInfo;
  const [receiptData, setReceiptData] = useState(null);
  const isHkg = checkPaymentInfo && accountInfo?.isHkgNation;

  const formattedRefundInfo = formatRefundDetail(detailRefundInfo, isHkg);
  const formattedPaymentInfo =
    checkPaymentInfo &&
    formatRefundPaymentInfo(accountInfo, detailRefundInfo.customerNational);
  const formattedAddressInfo =
    checkPaymentInfo && formatRefundAddressInfo(accountInfo);
  const formattedAccountInfo =
    checkPaymentInfo && formatAccountInfo(accountInfo);
  const formattedAccountEngInfo =
    checkPaymentInfo && formatAccountEngInfo(accountInfo);

  const downloadMutation = useMutation((payload) => getReceiptData(payload), {
    retry: false,
    onSuccess: (data) => {
      setReceiptData(formatReceiptForm(data));
    },
  });

  const updateMutation = useMutation(
    ['updateMedicalInfo'],
    (payload) => updateRefundDetail(refundIndex, payload),
    {
      retry: false,
      onSuccess: () => {
        setIsEdit(false);
        alert('환급현황 수정이 완료되었습니다.');
      },
    },
  );

  const onClickEdit = () => {
    if (isEdit) {
      const payload = {
        approvalFinishDate: detailRefundInfo.approvalFinishDate,
        memo: detailRefundInfo.memo,
        mailSendCount: detailRefundInfo.mailSendCount,
        transferDate: detailRefundInfo.paymentCompleteDate,
        mailSendDate: detailRefundInfo.lastSentDate,
        paymentStatus: detailRefundInfo.paymentStatus,
        needFullPayment: detailRefundInfo.needFullPayment,
        accountInfo: accountInfo ? JSON.stringify(accountInfo) : null,
        isModifyNeeded,
        selectedAccountInfo,
      };
      updateMutation.mutate(payload);
    } else {
      setIsEdit(true);
    }
  };

  const onClickDownload = () => {
    downloadMutation.mutate({ refundIndex });
  };

  const handleDownload = async () => {
    if (files.length < 1) return;
    const blob = await convertComponentToImage(files[0]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `환급전표_${refundIndex}`;
    link.click();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    handleDownload();
  }, [files]);

  const onChangeApprovalFinishDate = (e) => {
    setData((prev) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          approvalFinishDate: e.target.value,
        },
      };
    });
  };

  const onChangeMemo = (e) => {
    setData((prev) => {
      return {
        ...prev,
        detailRefundInfo: {
          ...prev.detailRefundInfo,
          memo: e.target.value,
        },
      };
    });
  };

  const onChange = (e) => {
    const name = e.target.name;
    setData((prev) => {
      return {
        ...prev,
        accountInfo: {
          ...prev.accountInfo,
          [name]: e.target.value,
        },
      };
    });
  };

  const selectModifyNeed = () => {
    if (!isEdit) return;
    if (isModifyNeeded) {
      setSelectedAccountInfo([]);
    }
    setIsModifyNeeded(!isModifyNeeded);
  };

  const selectCell = (item) => {
    if (isEdit) {
      if (selectedAccountInfo.includes(item?.option?.name)) {
        const filteredCell = selectedAccountInfo.filter(
          (cell) => cell !== item?.option?.name,
        );
        setSelectedAccountInfo(filteredCell);
      } else {
        setSelectedAccountInfo((prev) => [...prev, item?.option?.name]);
      }
    }
  };

  return (
    <StyledContainer>
      <FlexWrap
        justifyContent='space-between'
        alignItems='center'
        margin='0 0 32px 0'
      >
        <Title>환급 현황 상세보기</Title>
        <FlexWrap gap='16px'>
          <CloseBtn onClick={onClose}>닫기</CloseBtn>
          <EditButton isEdit={isEdit} onClick={onClickEdit}>
            {isEdit ? '수정완료' : '수정하기'}
          </EditButton>
        </FlexWrap>
      </FlexWrap>
      {/* 송금상태, 결제방식, 송금일자 */}
      {paymentStatus && (
        <FlexWrap alignItems='center' margin='0 0 36px 0'>
          <TransferStatus
            style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          >
            <RemittanceStatusRadio
              refundInfo={detailRefundInfo}
              isEdit={isEdit}
              setData={setData}
            />
            {/* 결제방식 (즉시환급만 보여짐 / 키오스크(x)) */}
            {!detailRefundInfo.paymentCompleteDate &&
              paymentStatus === 'PAYMENT_COMPLETE' && (
                <PaymentWayRadio
                  detailRefundInfo={detailRefundInfo}
                  isEdit={isEdit}
                  setData={setData}
                />
              )}
          </TransferStatus>
          <MailingStatusRadio
            detailRefundInfo={detailRefundInfo}
            isEdit={isEdit}
            setData={setData}
          />
        </FlexWrap>
      )}
      {data?.isModifyPossible && (
        <ModifyNeedCheckbox
          isModifyNeeded={isModifyNeeded}
          onClick={selectModifyNeed}
        />
      )}
      <MemoInput
        placeholder='메모를 입력하세요. (최대 1000자)'
        disabled={!isEdit}
        onChange={onChangeMemo}
        value={detailRefundInfo.memo}
      />

      {/* 기본정보 */}
      <FlexWrap
        alignItems='center'
        justifyContent='space-between'
        margin='0 0 24px 0'
      >
        <Typography fontSize='18px' lineHeight='150%' fontWeight='700'>
          No.{refundIndex}
        </Typography>
        <Download onClick={onClickDownload}>
          <Icon imgUrl={downloadIcon} widht='16px' height='16px' />
          <Typography>환급 전표 이미지 파일</Typography>
        </Download>
      </FlexWrap>
      <StyledTable width='100%' align='center'>
        {formattedRefundInfo?.map((items, index) => (
          <tbody key={index}>
            <StyledTr>
              {items.map((item, idx) => (
                <Fragment key={idx}>
                  <StyledTh
                    isValue={
                      !item?.option?.value || item?.option?.value === '-'
                    }
                  >
                    {item.label}
                  </StyledTh>
                  <StyledTd
                    isEdit={item.label === '환급 확정일자' ? isEdit : false}
                  >
                    {item.label === '환급 확정일자' ? (
                      <TextField
                        id='datetime-local'
                        disabled={!isEdit}
                        name={item.option.name}
                        type='datetime-local'
                        style={{ paddingLeft: 21 }}
                        value={detailRefundInfo.approvalFinishDate || ''}
                        onChange={onChangeApprovalFinishDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : (
                      <StyledInput disabled value={item.option.value || ''} />
                    )}
                  </StyledTd>
                </Fragment>
              ))}
            </StyledTr>
          </tbody>
        ))}
      </StyledTable>

      {/* 송금정보1 */}
      {accountInfo && (
        <>
          <Typography
            fontSize='18px'
            lineHeight='150%'
            fontWeight='700'
            margin='0 0 32px 0'
          >
            송금 정보
          </Typography>
          <StyledTable width='100%' align='center'>
            {formattedPaymentInfo?.map((items, index) => (
              <tbody key={index}>
                <StyledTr>
                  {items.map((item, idx) =>
                    ['firstNameEng', 'lastNameEng'].includes(
                      item.option.name,
                    ) && detailRefundInfo.customerNational !== 'CHN' ? (
                      <></>
                    ) : (
                      <Fragment key={idx}>
                        <StyledTh
                          isValue={
                            !item?.option?.value || item?.option?.value === '-'
                          }
                        >
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent='space-between'
                              onClick={() => selectCell(item)}
                            >
                              {item.label}
                              <Icon
                                imgUrl={
                                  selectedAccountInfo.includes(
                                    item?.option?.name,
                                  )
                                    ? checkedIconV2
                                    : checkIconV2
                                }
                                width='24px'
                                height='24px'
                                margin='0 21px 0 0'
                              />
                            </Checkbox>
                          ) : (
                            item.label
                          )}
                        </StyledTh>
                        <StyledTd>
                          <StyledInput
                            name={item.option.name}
                            disabled={
                              item?.option?.name === '' ||
                              item?.option?.name === 'email' ||
                              !isEdit
                            }
                            onChange={onChange}
                            value={item.option.value || ''}
                            type='text'
                            placeholder='-'
                          />
                        </StyledTd>
                      </Fragment>
                    ),
                  )}
                </StyledTr>
              </tbody>
            ))}
          </StyledTable>

          {/* 송금정보2 */}
          <StyledTable width='100%' align='center'>
            {formattedAddressInfo?.map((items, index) => (
              <tbody key={index}>
                <StyledTr>
                  {items.map((item, idx) => (
                    <Fragment key={idx}>
                      <StyledTh
                        isValue={
                          !item?.option?.value || item?.option?.value === '-'
                        }
                      >
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent='space-between'
                            onClick={() => selectCell(item)}
                          >
                            {item.label}
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width='24px'
                              height='24px'
                              margin='0 21px 0 0'
                            />
                          </Checkbox>
                        ) : (
                          item.label
                        )}
                      </StyledTh>
                      <StyledTd>
                        <StyledInput
                          name={item?.option?.name}
                          disabled={item?.option?.name === '' || !isEdit}
                          value={item?.option?.value || ''}
                          onChange={onChange}
                          type='text'
                          placeholder='-'
                        />
                      </StyledTd>
                    </Fragment>
                  ))}
                </StyledTr>
              </tbody>
            ))}
          </StyledTable>

          {/* 송금정보3 */}
          <StyledTable width='100%' align='center'>
            {formattedAccountInfo?.map((items, index) => (
              <tbody key={index}>
                <StyledTr>
                  {items.map((item, idx) => (
                    <Fragment key={idx}>
                      <StyledTh>
                        {item?.label && data?.isModifyPossible ? (
                          <Checkbox
                            justifyContent='space-between'
                            onClick={() => selectCell(item)}
                          >
                            <div>
                              <Label> {item.label} </Label>
                              <LabelNation>{item.nationLabel}</LabelNation>
                            </div>
                            <Icon
                              imgUrl={
                                selectedAccountInfo.includes(item?.option?.name)
                                  ? checkedIconV2
                                  : checkIconV2
                              }
                              width='24px'
                              height='24px'
                              margin='0 21px 0 0'
                            />
                          </Checkbox>
                        ) : (
                          item.label
                        )}
                      </StyledTh>
                      <StyledTd>
                        <StyledInput
                          name={item.option.name}
                          disabled={item?.option?.name === '' || !isEdit}
                          value={item.option.value || ''}
                          onChange={onChange}
                          type='text'
                          placeholder='-'
                        />
                      </StyledTd>
                    </Fragment>
                  ))}
                </StyledTr>
              </tbody>
            ))}
          </StyledTable>
          {/* 송금정보4 중국인 */}
          {detailRefundInfo.customerNational === 'CHN' && (
            <StyledTable width='100%' align='center'>
              {formattedAccountEngInfo?.map((items, index) => (
                <tbody key={index}>
                  <StyledTr>
                    {items.map((item, idx) => (
                      <Fragment key={idx}>
                        <StyledTh>
                          {item?.label && data?.isModifyPossible ? (
                            <Checkbox
                              justifyContent='space-between'
                              onClick={() => selectCell(item)}
                            >
                              <div>
                                <Label> {item.label} </Label>
                                <LabelNation>{item.nationLabel}</LabelNation>
                              </div>
                              <Icon
                                imgUrl={
                                  selectedAccountInfo.includes(
                                    item?.option?.name,
                                  )
                                    ? checkedIconV2
                                    : checkIconV2
                                }
                                width='24px'
                                height='24px'
                                margin='0 21px 0 0'
                              />
                            </Checkbox>
                          ) : (
                            item.label
                          )}
                        </StyledTh>
                        <StyledTd>
                          <StyledInput
                            name={item.option.name}
                            disabled={item?.option?.name === '' || !isEdit}
                            value={item.option.value || ''}
                            onChange={onChange}
                            type='text'
                            placeholder='-'
                          />
                        </StyledTd>
                      </Fragment>
                    ))}
                  </StyledTr>
                </tbody>
              ))}
            </StyledTable>
          )}
        </>
      )}
      {/* 전표 다운로드 */}
      {receiptData && (
        <ReceiptImage
          info={receiptData}
          nation={detailRefundInfo.customerNational}
          index={0}
          setFiles={setFiles}
          style={{
            height: '90%',
            overflowY: 'scroll',
          }}
        />
      )}
    </StyledContainer>
  );
}
const Title = styled.h2`
  font-size: 28px;
`;
export const SubTitle = styled.h3`
  font-size: 18px;
  line-height: 150%;
  font-weight: 500;
`;
const StyledInput = styled.input`
  color: black;
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  padding-left: 21px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  flex: 1;
  min-height: ${(props) => (props.isRadio ? 'auto' : '56px')};
  ${(props) =>
    props.disabled &&
    css`
      background-color: #f2f2f2;
    `}
`;

const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  height: 40px;
  font-size: 14px;
  width: 335px;
  background-color: ${(props) => (props.isEdit ? '#fff' : '#f2f2f2')};
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 56px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 335px;
  color: black;
  color: ${(props) => props.isValue && '#757575'};
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const StyledContainer = styled.div`
  width: 100%;
`;
const Label = styled.p`
  font-size: 14px;
  line-height: 28px;
`;
const LabelNation = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #5f6165;
`;
const EditButton = styled.button`
  background-color: ${(props) => (props.isEdit ? '#757575' : '#354eff')};
  color: #ffffff;
  border-radius: 4px;
  padding: 12px 44px;
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
const Download = styled.button`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #246cf6;
  cursor: pointer;
`;
const CloseBtn = styled.button`
  color: #3a3b3e;
  background-color: #e5e6e8;
  padding: 12px 44px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
`;
export const TransferContainer = styled(FlexWrap)`
  padding-left: 21px;
  gap: 20px;
  align-items: center;
`;
const TransferStatus = styled(FlexWrap)`
  position: relative;
  flex: 1;
  align-items: center;
`;

const MemoInput = styled.textarea`
  padding: 24px 20px;
  width: 100%;
  height: 72px;
  overflow: auto;
  margin-bottom: 40px;
  border-radius: 6px;
  outline: none;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  color: #3a3b3e;
  resize: none;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

export default DetailContent;

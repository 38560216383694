import { useState } from 'react';
import styled from 'styled-components';
import { Container, TableBody } from '@material-ui/core';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QNA_TABLE_DATA } from '@constants/ktp';
import DataTable from '@components/common/DataTable';
import Title from '@components/common/Title';
import LoadingView from '@/components/common/LoadingView';
import QnaItem from '@/components/qna/QnaItem';
import QnaFilter from '@/components/qna/QnaFilter';
import QnaModal from '@/components/qna/Qna.modal';
import useQnaReducer from '@/hooks/ktp/useQnaReducer';
import { getQnaMedicalList, patchQnaMedicalRead } from '@/api/medical';
import { QnaResponse } from '@/types/qna.type';
import useRemittanceReducer from '@/hooks/medical/useRemittanceReducer';
import DetailModal from '@/components/medical/refundStatus/DetailModal';
import Search from '@/components/common/Search';
import ViewDateSelection from '@/components/common/ViewDataSelection';
import Pagination from '@/components/common/Pagination';
import { useLocation } from 'react-router-dom';

function MedicalQna() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const searchKeyword = searchParams.get('search') || '';
  const size = searchParams.get('size') || '10';
  const [state, dispatch] = useQnaReducer();
  const { toggle, selected } = state;
  const [refundState, refundDispatch] = useRemittanceReducer();
  const { selected: refundSelected } = refundState;
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [Qnafilter, setQnaFilter] = useState('ALL');
  const { columns } = QNA_TABLE_DATA;
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ['medicalQnaList', Qnafilter, size, currentPage, searchKeyword],
    () => {
      const params = {
        status: Qnafilter,
        page: currentPage,
        size,
        keyword: searchKeyword,
      };
      return getQnaMedicalList(params);
    },
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
      keepPreviousData: true,
    },
  ) as any;

  const { mutate: readQna } = useMutation(
    (selected: number) => patchQnaMedicalRead(selected),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('medicalQnaList');
      },
    },
  );

  const onQnaModal = {
    open: (selected: number, isNew: boolean) => {
      if (isNew) {
        readQna(selected);
      }
      dispatch({
        type: 'OPEN_MODAL',
        payload: { selected },
      });
    },
    close: () => {
      dispatch({
        type: 'CLOSE_MODAL',
      });
    },
  };

  const onRefundModal = {
    open: (refundIndex: number) => {
      refundDispatch({
        type: 'SET_SELECTED',
        payload: { refundIndex },
      });
    },
    close: () => {
      refundDispatch({ type: 'SET_SELECTED', selected: null });
    },
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <StyledContainer maxWidth='xl'>
      <Title>FAQ</Title>
      <Search data={[]} />
      <FilterWrapper>
        <QnaFilter
          currentTransferView={Qnafilter}
          onClickChangeTransfer={(value) => setQnaFilter(value)}
        />
        <ViewDateSelection initialSize={size} />
      </FilterWrapper>
      <DataTable
        columns={columns}
        dispatch={dispatch}
        toggle={toggle}
        contentComponent={
          <TableBody>
            {data?.content.map((question: QnaResponse) => (
              <QnaItem
                key={question.questionId}
                id={question.questionId}
                data={question}
                onClick={() =>
                  onQnaModal.open(question.questionId, question.isNew)
                }
              />
            ))}
          </TableBody>
        }
      />
      {data?.content?.length === 0 && (
        <ContentContainer>문의가 존재하지 않습니다.</ContentContainer>
      )}
      <BottomSection>
        {totalPage > 0 && (
          <Pagination
            initialPage={currentPage}
            totalPage={totalPage}
            isLastPage={isLastPage}
            isFirstPage={isFirstPage}
            displaySize={5}
          />
        )}
      </BottomSection>
      {selected && (
        <QnaModal
          qnaId={selected}
          onClose={onQnaModal.close}
          onOpenRefundModal={onRefundModal.open}
        />
      )}
      {refundSelected?.refundIndex && (
        <DetailModal onClose={onRefundModal.close} selected={refundSelected} />
      )}
    </StyledContainer>
  );
}

export default MedicalQna;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  color: gray;
  font-size: 16px;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 56px;
`;

const BottomSection = styled.div`
  margin: 20px 0;
`;

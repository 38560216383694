import React, { useState } from 'react';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';

import ActionBtn from '@/components/common/ActionBtn';
import { signUpTableData } from '@/constants/medical';
import { signUp } from '@/api/medical';

function SignUpContent({ setHospitalIndex, hospitalIndex }) {
  const [formData, setFormData] = useState({
    managerName: '',
    hospitalTelNumber: '',
    businessNumber: '',
    email: '',
    password: '',
    hospitalName: '',
    hospitalAddress: '',
    hospitalPhoneNumber: '',
    userId: '',
    superiorId: '',
  });
  const {
    managerName,
    hospitalTelNumber,
    businessNumber,
    email,
    password,
    hospitalName,
    hospitalAddress,
    hospitalPhoneNumber,
  } = formData;
  const mutation = useMutation((payload) => signUp(payload), {
    onSuccess: (data) => {
      alert('계정 생성이 완료되었습니다.');
      setHospitalIndex(data);
    },
    onError: (err) => {
      const code = err?.response?.data?.code;
      let message;
      switch (code) {
        case 'P0002':
          message = '비밀번호 형식에 맞지 않습니다.';
          break;
        case 'B0001':
          message = '사업자번호 형식에 맞지 않습니다.';
          break;
        case 'R0001':
          message = '이미 가입된 사업자등록번호입니다.';
          break;
        default:
          message = '계정 생성에 실패하였습니다. ';
      }
      alert(message);
    },
    retry: false,
  });

  const getProcessedValue = (name, value) => {
    const numericFields = [
      'businessNumber',
      'hospitalTelNumber',
      'hospitalPhoneNumber',
      'superiorId',
    ];
    if (numericFields.includes(name)) {
      return value.replace(/[^\d]/g, '');
    }
    if (name === 'userId') {
      return value.replace(/[^A-Za-z0-9]/g, '');
    }
    return value;
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    const processedValue = getProcessedValue(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: processedValue,
    }));
  };
  const onSubmit = () => {
    const isBtnActive = Boolean(
      managerName.trim() &&
        hospitalTelNumber.trim() &&
        businessNumber.trim() &&
        email.trim() &&
        password.trim() &&
        hospitalName.trim() &&
        hospitalAddress.trim() &&
        hospitalPhoneNumber.trim(),
    );
    if (!isBtnActive) {
      alert('모든 정보를 입력해주세요.');
    } else if (businessNumber.length !== 10) {
      alert('사업자등록번호를 10자리로 입력해주세요.');
    } else if (
      !email.match(/[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i)
    ) {
      alert('이메일 형식이 옳지 않습니다.');
    } else {
      const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
        const value = formData[key];
        acc[key] = typeof value === 'string' ? value.trim() : value;
        return acc;
      }, {});

      mutation.mutate({
        ...trimmedFormData,
        businessNumber: businessNumber.replace(/[^0-9]/g, ''),
      });
    }
  };
  return (
    <TableContainer>
      <Title>회원 계정 생성</Title>
      <StyledTable width='100%' align='center'>
        {signUpTableData.map((items) => (
          <StyledTr>
            {items.map((item) => (
              <>
                <StyledTh required={item.required || false}>
                  {item.label}
                </StyledTh>
                <StyledTd isActive={!hospitalIndex}>
                  <StyledInput
                    {...item.option}
                    value={formData[item.option.name]}
                    onChange={onChange}
                  />
                </StyledTd>
              </>
            ))}
          </StyledTr>
        ))}
      </StyledTable>
      <ActionBtn title='생성하기' onClick={onSubmit} right='0' bottom='-45px' />
    </TableContainer>
  );
}
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 16px;
`;
const TableContainer = styled.div`
  position: relative;
`;
const borderStyles = css`
  border-collapse: collapse;
  border: 1px solid black;
`;
const StyledTable = styled.table`
  ${borderStyles}
  margin: 0 0 40px;
`;
const StyledTd = styled.td`
  ${borderStyles}
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  min-width: 120px;
  width: 350px;
  ${(props) =>
    !props.isActive &&
    css`
      background-color: #f2f2f2;
    `}
`;
const StyledTh = styled.td`
  ${borderStyles}
  padding-left: 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #d3d3d3;
  width: 190px;

  ${({ required = false }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: red;
        margin-left: 4px;
      }
    `}
`;

const StyledTr = styled.tr`
  ${borderStyles}
  padding: 8px 15px;
  font-size: 14px;
`;
const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 8px 0;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export default SignUpContent;

import { useState } from 'react';
import { TableBody } from '@material-ui/core';
import { useQuery } from 'react-query';

import { getKioskStatus } from '@/api/kiosk';
import DataTable from '@/components/common/DataTable';
import { kioskStatusData } from '@/constants/kiosk';
import useKioskReducer from '@/hooks/kiosk/useKioskReducer';
import {
  GetKioskStatusPayload,
  GetKioskStatusResponse,
} from '@/types/api/kiosk';
import KioskList from './KioskList';
import KioskTitle from './KioskTitle';
import { getKeywords } from '@/api/kiosk';
import LoadingView from '@/components/common/LoadingView';
import KioskFilter from '../kioskFilter/KioskFilter';
import { KioskActiveTypes } from '@/types/kiosk.type';
import styled from 'styled-components';
import Search from '@/components/common/Search';
import ViewDataSelection from '@/components/common/ViewDataSelection';
import Pagination from '@/components/common/Pagination';
import { useLocation } from 'react-router-dom';

function KioskStatusList() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = searchParams.get('page') || '0';
  const keyword = searchParams.get('search') || '';
  const size = searchParams.get('size') || '100';
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [kioskActiveTypeFilter, setKioskActiveTypeFilter] = useState<
    KioskActiveTypes[]
  >(['ACTIVATED']);
  const { columns } = kioskStatusData;

  const [dispatch] = useKioskReducer();

  // 키워드 검색
  const { data: keywords } = useQuery('keywords', getKeywords, {
    retry: false,
  });

  const { data: kioskData, isLoading } = useQuery<
    GetKioskStatusResponse,
    GetKioskStatusPayload
  >(
    ['kioskStatus', currentPage, keyword, size, kioskActiveTypeFilter],
    () =>
      getKioskStatus({
        page: Number(currentPage),
        size: Number(size),
        keyword,
        view:
          kioskActiveTypeFilter.length === 2 ? 'ALL' : kioskActiveTypeFilter[0],
      }),
    {
      onSuccess: ({ totalPages, first, last }) => {
        setTotalPage(totalPages);
        setIsFirstPage(first);
        setIsLastPage(last);
      },
      retry: false,
    },
  );

  return (
    <>
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <KioskTitle title='홈' />
          {keywords && <Search data={keywords} />}
          <Wrapper>
            <KioskFilter
              kioskActiveTypes={kioskActiveTypeFilter}
              setKioskActiveType={setKioskActiveTypeFilter}
            />
            <ViewDataSelection
              options={[10, 100, 500, 10000]}
              initialSize={size}
            />
          </Wrapper>
          <DataTable
            columns={columns}
            dispatch={dispatch}
            contentComponent={
              <TableBody>
                {kioskData?.content.map((item, idx) => (
                  <KioskList
                    key={idx}
                    idx={idx}
                    data={item}
                    size={kioskData.size}
                    number={kioskData.number}
                    totalElements={kioskData.totalElements}
                  />
                ))}
              </TableBody>
            }
          />
          <BottomSection>
            {totalPage > 0 && (
              <Pagination
                initialPage={currentPage}
                totalPage={totalPage}
                isLastPage={isLastPage}
                isFirstPage={isFirstPage}
                displaySize={5}
              />
            )}
          </BottomSection>
        </>
      )}
    </>
  );
}

export default KioskStatusList;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BottomSection = styled.div`
  margin: 20px 0;
`;
